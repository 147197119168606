<template>
    <v-card id="account-setting-card">
     <v-card-actions>
<iframe title="Feedback Form" class="freshwidget-embedded-form" id="freshwidget-embedded-form" src="https://fairpay.freshdesk.com/widgets/feedback_widget/new?&widgetType=embedded&submitTitle=Submit+Ticket&searchArea=no" scrolling="no" height="850px" width="100%" frameborder="0" style="padding: 10px;" >
</iframe>
</v-card-actions>

    </v-card>
  </template>

<style type="text/css" scoped media="screen, projection">
	@import url(https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.css); 
</style> 

  <script type="text/javascript" src="https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.js"></script>
  <script>
  import { mdiFaceAgent } from '@mdi/js'
 
  
  export default {
    setup() {
        
    }
}
  </script>
